import { logError } from '@/utils/error-handling.js'

const defaultRatingTypes = {
	legal:
	[
		{ key: 'price', name: 'Price' },
		{ key: 'service', name: 'Service' },
		{ key: 'location', name: 'Location' },
		{ key: 'success_rate', name: 'Success Rate' },
		{ key: 'dependability', name: 'Dependability' }
	],
	retail:
	[
		{ key: 'price', name: 'Price' },
		{ key: 'quality', name: 'Quality' },
		{ key: 'service', name: 'Service' },
		{ key: 'variety', name: 'Variety' },
		{ key: 'location', name: 'Location' }
	],
	hydro:
	[
		{ key: 'price', name: 'Price' },
		{ key: 'quality', name: 'Quality' },
		{ key: 'service', name: 'Service' },
		{ key: 'variety', name: 'Variety' },
		{ key: 'knowledge', name: 'Knowledge' }
	],
	dispensary:
	[
		{ key: 'med', name: 'Quality' },
		{ key: 'price', name: 'Price' },
		{ key: 'location', name: 'Location' },
		{ key: 'knowledge', name: 'Knowledge' },
		{ key: 'budtenders', name: 'Budtenders' }
	],
	delivery:
	[
		{ key: 'med', name: 'Quality' },
		{ key: 'price', name: 'Price' },
		{ key: 'knowledge', name: 'Knowledge' },
		{ key: 'waitTime', name: 'Speediness' },
		{ key: 'budtenders', name: 'Bud Drivers' }
	],
	clinic:
	[
		{ key: 'med', name: 'Quality' },
		{ key: 'price', name: 'Price' },
		{ key: 'location', name: 'Location' },
		{ key: 'waitTime', name: 'Wait Time' },
		{ key: 'budtenders', name: 'Staff' }
	],
	smoke:
	[
		{ key: 'price', name: 'Price' },
		{ key: 'quality', name: 'Quality' },
		{ key: 'service', name: 'Service' },
		{ key: 'variety', name: 'Variety' },
		{ key: 'location', name: 'Location' }
	],
	seed:
	[
		{ key: 'price', name: 'Price' },
		{ key: 'quality', name: 'Quality' },
		{ key: 'service', name: 'Service' },
		{ key: 'variety', name: 'Variety' },
		{ key: 'knowledge', name: 'Knowledge' }
	]
}

const mapRatingTypes = (businessType) => {
	if (Object.keys(defaultRatingTypes).includes(businessType)) {
		return defaultRatingTypes[businessType]
	} else {
		logError(Error('business type does not exist in ratings map'))
	}
}

const setReviewInput = (review, newRatings) => {
	const reviewInput = {
		review: review
	}
	Object.keys(newRatings).forEach(key => {
		if (key) {
			Object.assign(reviewInput, { [key]: newRatings[key] })
		}
	})
	return reviewInput
}

const mapRatingsObjectToArray = (ratingsObject, typename) => {
	const ratingKeys = Object.keys(ratingsObject)
	const ratingValues = Object.values(ratingsObject)
	const ratingsArray = []
	for (let i = 0; i < ratingKeys.length; i++) {
		ratingsArray.push({
			key: ratingKeys[i],
			rating: ratingValues[i],
			__typename: typename
		})
	}
	return ratingsArray
}

export { defaultRatingTypes, mapRatingTypes, setReviewInput, mapRatingsObjectToArray }
