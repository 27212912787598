<template>
	<div class="flex items-center justify-between text-xs font-bold border rounded border-gray-300 h-8 w-14 px-1.5">
		<img
			src="@/assets/icons/star-full-yellow.svg"
			alt="Star"
			height="12"
			width="12"
		>
		<span>
			{{ rating }}
		</span>
	</div>
</template>

<script async>
export default {
	props: {
		rating: {
			type: String,
			default: '0.0'
		}
	}
}
</script>
