<template>
	<div
		:id="review_id"
		:class="{ 'border-red-500' : isReported }"
		class="mb-4 border rounded-lg shadow-md"
	>
		<div class="flex flex-wrap justify-between p-4 pb-4 align-center">
			<div class="flex">
				<div class="h-13 w-13">
					<UserAvatar
						v-if="shouldShowUserProfile"
						:user="review?.user"
						:should-show-verified="isVerifiedUser"
					/>
					<BusinessAvatar
						v-else
						:image="businessImage"
					/>
				</div>

				<div class="flex flex-col justify-center ml-3">
					<!-- User specific -->
					<template v-if="profile === 'user' && review?.listing">
						<router-link
							:to="reviewListingUrl"
							class="text-base font-bold text-left text-black"
						>
							{{ review.listing.name }}
						</router-link>
					</template>

					<!-- Business specific -->
					<template v-else-if="profile == 'business'">
						<router-link
							:to="`/users/${reviewUserUrl}`"
							class="text-base font-bold text-black"
						>
							{{ username }}
						</router-link>
					</template>

					<span
						v-if="review.featured == 1"
						class="border"
					>
						FEATURED
					</span>

					<div class="text-xs text-left">
						{{ formattedTime }}
					</div>
				</div>
			</div>
			<div
				v-if="overallRating"
				class="flex align-center"
			>
				<WwStarRating :rating="overallRating" />
			</div>
		</div>

		<p
			class="pb-3 mx-4 text-sm text-left border-b"
			v-html="review.review"
		/>
		<div class="flex items-center justify-between h-10">
			<div>
				<span
					v-if="isVerifiedPurchase"
					class="px-2 mx-3 py-1.5 text-xs text-center font-bold text-green-500 uppercase bg-green-100 border-2 border-green-500 rounded-md"
				>
					Verified Purchase
				</span>
			</div>
			<WwDropdown
				v-if="showDropDown"
				:button-id="`review-card-${review_id}`"
				:menu-items="dropdownItems"
				:force-open-position="RIGHT"
				show-top-arrow
				title=""
				class="p-3"
				:x-offset-override="22"
				@menu-clicked="handleMenuClick"
			>
				<template #button="{ clicked }">
					<button
						aria-label="review-options"
						class="grid grid-cols-3 px-2 py-2 transition-all duration-100 ease-in-out rounded-md justify-items-center gap-x-1 hover:bg-gray-200"
						@click="clicked"
					>
						<span
							v-for="i in 3"
							:key="i"
							class="w-1 h-1 bg-gray-500 border-2 border-gray-500 rounded-full"
						/>
					</button>
				</template>
			</WwDropdown>
		</div>
		<div v-if="showReplies">
			<BusinessReviewReply
				v-for="(response, index) in review.replies"
				:key="index"
				:response="response"
				:is-logged-in="isLoggedIn"
			/>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import BusinessAvatar from '@/components/business/BusinessAvatar.vue'
import BusinessReviewReply from '@/components/business/BusinessReviewReply.vue'
import WwDropdown from '@/components/UI/WwDropdown.vue'
import WwStarRating from '@/components/UI/WwStarRating.vue'
import UserAvatar from '@/components/user/UserAvatar.vue'
import { MONTHS } from '@/constants/months.js'
import { RIGHT } from '@/constants/positions.js'
import ratingTypes from '@/etc/generated/ratingTypes.json'
import { formatUnixDateTime } from '@/utils/formatUnixTime.js'

const BUSINESS = 'business'
const USER = 'user'

export default {
	name: 'ReviewCard',
	components: {
		BusinessReviewReply,
		UserAvatar,
		BusinessAvatar,
		WwStarRating,
		WwDropdown
	},
	props: {
		review: {
			type: Object,
			default: () => ({})
		},
		profile: {
			type: String,
			default: 'user'
		},
		listingId: {
			type: Number,
			required: true
		},
		canEdit: {
			type: Boolean,
			default: false
		},
		user: {
			type: Object,
			default: () => ({})
		}
	},
	emits: [ 'open-report-modal', 'open-edit-modal', 'open-delete-modal' ],
	data() {
		return {
			response: '',
			review_id: this.review.id,
			ratingTypes: ratingTypes,
			MONTHS,
			RIGHT
		}
	},
	computed: {
		shouldShowUserProfile() {
			return this.profile === BUSINESS
		},
		showAvatar() {
			return !!this.review?.user
		},
		ratingCategories () {
			return this.review.ratings && ratingTypes[this.review.ratings.entity] || []
		},
		overallRating() {
			const tempOverall = this.review?.ratings?.overall ?? this.review?.overallRating
			if (tempOverall) return parseFloat(tempOverall).toFixed(1)
			return ''
		},
		reviewAuthorId() {
			return this.review?.user?.id || this.review?.userid || this.review?.user?.id || this.user?.id
		},
		reviewAuthorPhoto() {
			if (
				this.review.profile_picture == '/user_images/50x50/default.png' ||
        this.review.profile_picture == '/user_images/60x60/default.png' ||
        this.review.profile_picture == ''
			) {
				return ''
			} else {
				return '/user_images/120x120/' + this.review.profile_picture
			}
		},
		featuredData() {
			if (this.profile == BUSINESS) {
				return {
					review_id: this.review.id,
					business_id: this.listing?.id
				}
			} else {
				return {}
			}
		},
		reviewId() {
			if (this.profile == BUSINESS || this.profile == USER) {
				return {
					review_id: this.review.id,
					business_id: this.review.listing?.Id
				}
			}
			return false
		},
		...mapGetters('auth', [ 'auth', 'isLoggedIn' ]),
		reviewUserUrl() {
			return this.review?.user?.type === 'local' ? this.review?.user?.username : this.review?.user?.id
		},
		reviewListingUrl() {
			return this.review?.listing?.url || ''
		},
		isVerifiedPurchase() {
			return !!this.review?.user?.verifiedCustomer || this.review?.verified_buyer
		},
		isVerifiedUser() {
			return !!this.review?.user?.verifiedMember
		},
		isReported() {
			return !!this.review?.reports
		},
		businessImage() {
			return this.review?.listing?.logo?.fileName || ''
		},
		showReplies() {
			return this.review?.replies && this.profile == BUSINESS
		},
		username() {
			return this.review?.user?.displayName || this.review?.user?.username || this.review?.username
		},
		showEditButton() {
			return this.canEdit && this.auth.id == this.reviewAuthorId
		},
		showReportButton() {
			return this.isLoggedIn && this.auth.id !== this.reviewAuthorId
		},
		dropdownItems() {
			const items = []
			if (this.showEditButton) {
				items.push({ text: 'Edit', value: 'showEditModal', type: 'button' }, { text: 'Delete', value: 'showDeleteModal', type: 'button' })
			}
			if (this.showReportButton) {
				items.push({ text: 'Report', value: 'showReportModal', type: 'button' })
			}
			return items
		},
		showDropDown() {
			return this.dropdownItems?.length
		},
		formattedTime() {
			return formatUnixDateTime(this.creationTime)
		},
		creationTime() {
			return this.review?.creationTime ?? this.review?.creation_time
		}
	},
	mounted() {
		if (this.review.reported == 1) {
			this.hasBeenReported = true
		} else {
			this.hasBeenReported = false
		}
	},
	methods: {
		formatRating(rating) {
			if (rating == null) {
				return 0
			} else {
				return rating
			}
		},
		showEditModal() {
			this.$emit('open-edit-modal', this.review)
		},
		showReportModal() {
			this.$emit('open-report-modal', this.review)
		},
		showDeleteModal() {
			this.$emit('open-delete-modal', this.review)
		},
		handleMenuClick(value) {
			this[value]()
		}
	}

}
</script>
