<template>
	<div class="bg-gray-200">
		<div class="px-3 py-4 text-sm">
			<div class="flex items-center justify-between pb-2">
				<h4 class="text-sm font-bold">
					Official Response
					<span class="font-normal text-gray-500">
						- {{ reviewReplyDate }}
					</span>
				</h4>
				<WwDropdown
					v-if="showDropDown"
					:button-id="`review-card-${responseId}`"
					:menu-items="dropdownItems"
					:force-open-position="RIGHT"
					show-top-arrow
					title=""
					@menu-clicked="handleMenuClick"
				>
					<template #button="{ clicked }">
						<button
							class="grid grid-cols-3 px-2 py-2 transition-all duration-100 ease-in-out rounded-md justify-items-center gap-x-1 hover:bg-white"
							@click="clicked"
						>
							<span
								v-for="i in 3"
								:key="i"
								class="w-1 h-1 bg-gray-500 border-2 border-gray-500 rounded-full"
							/>
						</button>
					</template>
				</WwDropdown>
			</div>
			<p>
				{{ reviewReplyContent }}
			</p>
		</div>
	</div>
</template>

<script async>
import WwDropdown from '@/components/UI/WwDropdown.vue'
import { MONTHS } from '@/constants/months.js'
import { RIGHT } from '@/constants/positions.js'
import { convertToDate } from '@/utils/formatUnixTime.js'

export default {

	name: 'BusinessReviewReply',
	components: {
		WwDropdown
	},
	props: {
		response: {
			type: Object,
			default: () => ({})
		},
		isLoggedIn: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			MONTHS,
			RIGHT
		}
	},
	computed: {
		reviewReplyDate() {
			if (this.response?.creationTime) {
				const { month, year } = convertToDate(this.response.creationTime)
				return `${MONTHS[month]} ${year}`
			} else return ''
		},
		reviewReplyContent() {
			return this.response?.content
		},
		showReportButton() {
			return this.isLoggedIn
		},
		dropdownItems() {
			const items = []
			if (this.showReportButton) {
				items.push({ text: 'Report', value: 'showReportModal', type: 'button' })
			}
			return items
		},
		showDropDown() {
			return this.dropdownItems?.length
		},
		responseId() {
			return this.response?.id || ''
		}
	},
	methods: {
		showReportModal() {
			this.$emit('open-report-modal', this.response)
		},
		handleMenuClick(value) {
			this[value]()
		}
	}
}
</script>
